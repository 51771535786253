import "./_style.less";
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import Menu from "antd/es/menu";
import Dropdown from "antd/es/dropdown";
import {
  KeyOutlined,
  LogoutOutlined,
  MenuOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Space from "antd/es/space";
import Avatar from "antd/es/avatar/avatar";
import Typography from "antd/es/typography";
import Drawer from "antd/es/drawer";
import { getAliasesName } from "../../../utils/helpers";
import VuiSidebar from "../../../components/sidebar";
import VuiBreadcrumb from "../../../components/breadcrumb";
import { useAuth } from "../../../context/auth";
import { useTranslation } from "react-i18next";
import { generateMenu } from "../../../constants/menu";
import { ENV } from "../../../constants";

const { Text } = Typography;

const VuiHeader = () => {
  const { t } = useTranslation();
  const { state, logout } = useAuth();
  const menus = generateMenu(t);
  const [showDrawerMenu, setShowDrawerMenu] = useState<boolean>(false);

  const handleCloseDrawerMenu = useCallback(() => {
    setShowDrawerMenu(false);
  }, []);

  return (
    <>
      <header className="vui-header">
        <div className="brand-wrapper">
          <div className="hide-lg">
            {state.phase === "auth" ? (
              <>
                <MenuOutlined
                  className="menu-icon"
                  style={{
                    fontSize: 18,
                  }}
                  onClick={() => setShowDrawerMenu(true)}
                />

                <Drawer
                  title={
                    <div className="drawer-logo-mobile-wrapper">
                      <img
                        src={`/assets/images/${
                          ENV.getNodeEnv() === "production"
                            ? "app-logo"
                            : "staging-logo"
                        }.png`}
                        alt="logo"
                      />
                    </div>
                  }
                  placement={"left"}
                  closable={true}
                  onClose={handleCloseDrawerMenu}
                  visible={showDrawerMenu}
                  className="header-menu-drawer"
                >
                  <VuiSidebar
                    menus={menus}
                    mode="inline"
                    onChange={handleCloseDrawerMenu}
                  />
                </Drawer>
              </>
            ) : null}
          </div>

          <Link to={"/"}>
            <img
              className="logo"
              src={`/assets/images/${
                ENV.getNodeEnv() === "production" ? "app-logo" : "staging-logo"
              }.png`}
              alt="logo"
            />
          </Link>
        </div>

        <div className="show-lg">{/*<VuiBreadcrumb />*/}</div>

        <div className="profile-wrapper">
          {state.phase === "auth" && state.user ? (
            <div className="auth-wrapper">
              <Space className="auth-spaces" size={15}>
                <Dropdown
                  overlayClassName={"main-header-dropdown"}
                  overlay={
                    <Menu>
                      <Menu.Item key="photo" className="avatar-menu-item">
                        <div className="avatar-wrapper">
                          <Avatar
                            size="large"
                            className="mb6"
                            src={state.user?.photo?.url}
                          >
                            {getAliasesName(state.user?.name)}
                          </Avatar>
                          <Text strong>{state.user?.name}</Text>
                        </div>
                      </Menu.Item>

                      <Menu.Divider />

                      <Menu.Item key="profile">
                        <Link to={"/profile"}>
                          <UserOutlined className="icon" />
                          {t("common.text.viewProfile")}
                        </Link>
                      </Menu.Item>

                      <Menu.Item key="change-password">
                        <Link to={"/change-password"}>
                          <KeyOutlined className="icon" />
                          {t("common.text.changePassword")}
                        </Link>
                      </Menu.Item>

                      <Menu.Divider />

                      <Menu.Item key="logout">
                        <div onClick={logout}>
                          <LogoutOutlined className="icon" />
                          {t("common.text.logout")}
                        </div>
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <div>
                    <Avatar size="large" src={state.user?.photo?.url}>
                      {getAliasesName(state.user?.name)}
                    </Avatar>
                  </div>
                </Dropdown>
              </Space>
            </div>
          ) : null}
        </div>
      </header>
    </>
  );
};

export default VuiHeader;
