import { Card, Col, Form, InputNumber, Row, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useGetDomain } from "../hooks/use-get-domain";
import Input from "antd/es/input";
import VuiFormActionButton from "../../../components/form-action-button";
import VuiSelect from "../../../components/select";
import { useCallback, useEffect } from "react";
import VuiPageTitle from "../../../components/page-title";
import { useFormDomain } from "../hooks/use-form-domain";
import { DomainPayloadType, DomainFormData } from "./interface";
import { convertToSelectValue } from "../../../components/select/function";
import UserRepository from "../../../repositories/user-repository";
import CustomerRepository from "../../../repositories/customer-repository";
import ProjectRepository from "../../../repositories/project-repository";
import VuiDatePicker from "../../../components/vui-date-picker";
import moment from "moment";
import ConstantRepository from "../../../repositories/constant-repository";
import ThirdPartyRepository from "../../../repositories/third-party-repository";

const DomainFormModule = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const title = id ? t("pages.domain.edit.title") : t("pages.domain.add.title");
  const { loadDetailData, isOnFetchingDetailData, detailData } = useGetDomain();
  const { submitLoading, onSubmit } = useFormDomain();

  const [form] = Form.useForm();

  const selectedCustomer = Form.useWatch("customer_id", form);

  const selectedProject = Form.useWatch("project_id", form);

  const onFinish = useCallback(
    async (data: DomainFormData) => {
      const normalizeData: DomainPayloadType = {
        customer_id: data.customer_id.value as number,
        project_manager_id: data.project_manager_id.value as number,
        project_id: data.project_id.value as number,
        name: data.name,
        date: moment(data.date).format("YYYY-MM-DD"),
        duration: data.duration,
        description: data.description,
        registrar_id: data.registrar_id.value as number,
        cdn_id: data.cdn_id.value as number,
      };

      await onSubmit(normalizeData, id);
    },
    [id, onSubmit]
  );

  useEffect(() => {
    if (id) {
      (async () => {
        await loadDetailData(id, {
          with: ["customer", "projectManager", "project", "registrar", "cdn"],
        });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id && detailData) {
      form.setFieldsValue({
        ...detailData,
        date: moment(detailData.date),
        customer_id: convertToSelectValue(detailData.customer),
        project_manager_id: convertToSelectValue(detailData.project_manager),
        project_id: {
          label: detailData?.project?.code,
          value: detailData?.project?.id,
        },
        registrar_id: convertToSelectValue(detailData.registrar),
        cdn_id: {
          label: detailData?.cdn?.label,
          value: detailData?.cdn?.id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailData]);

  return (
    <>
      <VuiPageTitle
        title={title}
        onBackLink={id ? `/domain/${id}` : "/domain"}
      />

      <Spin size="large" spinning={isOnFetchingDetailData}>
        <Form form={form} layout={"vertical"} onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col md={8} xs={24}>
              <Card title={t("common.text.generalInformation")}>
                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <Form.Item
                      name="customer_id"
                      label={t("common.form.client.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.client.label"),
                          }),
                        },
                      ]}
                    >
                      <VuiSelect
                        repository={CustomerRepository}
                        placeholder={t("common.form.client.placeholder")}
                        onChange={() =>
                          form.resetFields(["project_id", "project_manager_id"])
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      name="description"
                      label={t("common.form.description.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.description.label"),
                          }),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t("common.form.description.placeholder")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col md={16} xs={24}>
              <Card title={t("common.text.projectInformation")}>
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={24}>
                    <Form.Item
                      name="project_id"
                      label={t("common.form.idProject.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.idProject.label"),
                          }),
                        },
                      ]}
                    >
                      <VuiSelect
                        repository={ProjectRepository}
                        placeholder={t("common.form.idProject.placeholder")}
                        labelKey="code"
                        repositoryParams={{
                          customer: selectedCustomer?.value,
                        }}
                        onChange={() => {
                          form.resetFields(["project_manager_id"]);
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      name="name"
                      label={t("common.form.domainName.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.domainName.label"),
                          }),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t("common.form.domainName.placeholder")}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      name="project_manager_id"
                      label={t("common.form.projectManager.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.projectManager.label"),
                          }),
                        },
                      ]}
                    >
                      <VuiSelect
                        repository={UserRepository}
                        repositoryParams={{
                          project: selectedProject?.value,
                        }}
                        placeholder={t(
                          "common.form.projectManager.placeholder"
                        )}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      name="date"
                      label={t("common.form.registrationDate.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.registrationDate.label"),
                          }),
                        },
                      ]}
                    >
                      <VuiDatePicker />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      name="duration"
                      label={t("common.form.durationYear.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.durationYear.label"),
                          }),
                        },
                      ]}
                    >
                      <InputNumber
                        min={1}
                        size="large"
                        placeholder={t("common.form.durationYear.placeholder")}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      name="registrar_id"
                      label={t("common.form.registrarDomain.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.registrarDomain.label"),
                          }),
                        },
                      ]}
                    >
                      <VuiSelect
                        repository={ThirdPartyRepository}
                        placeholder={t(
                          "common.form.registrarDomain.placeholder"
                        )}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      name="cdn_id"
                      label={t("common.form.cdn.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.cdn.label"),
                          }),
                        },
                      ]}
                    >
                      <VuiSelect
                        repository={ConstantRepository}
                        repositoryParams={{
                          for: "cdn_domain",
                        }}
                        labelKey="label"
                        placeholder={t("common.form.cdn.placeholder")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col xs={24}>
              <VuiFormActionButton
                cancelBtnLink={id ? `/domain/${id}` : "/domain"}
                isLoading={submitLoading}
              />
            </Col>
          </Row>
        </Form>
      </Spin>
    </>
  );
};

export default DomainFormModule;
