import { Navigate, RouteObject } from "react-router-dom";
import React from "react";
import AppLayout from "../layouts/app";
import VuiLoadingScreen from "../components/loading-screen";
import UserListPage from "../pages/user/list";
import UserFormPage from "../pages/user/form";
import ProjectListPage from "../pages/project/list";
import ProjectFormPage from "../pages/project/form";
import CustomerListPage from "../pages/customer/list";
import CustomerFormPage from "../pages/customer/form";
import MaintenanceListPage from "../pages/maintenance/list";
import MaintenanceFormPage from "../pages/maintenance/form";
import MaintenanceDetailPage from "../pages/maintenance/detail";
import DomainListPage from "../pages/domain/list";
import DomainFormPage from "../pages/domain/form";
import DomainDetailPage from "../pages/domain/detail";
import InvoiceListPage from "../pages/invoice/list";
import InvoiceFormPage from "../pages/invoice/form";
import InvoiceDetailPage from "../pages/invoice/detail";
import CategoryListPage from "../pages/category/list";
import CategoryFormPage from "../pages/category/form";
import IndustryListPage from "../pages/industry/list";
import IndustryFormPage from "../pages/industry/form";
import CredentialListPage from "../pages/credential/list";
import CredentialFormPage from "../pages/credential/form";
import ProjectTypeListPage from "../pages/project-type/list";
import ProjectTypeFormPage from "../pages/project-type/form";
import UnitListPage from "../pages/unit/list";
import UnitFormPage from "../pages/unit/form";
import ThirdPartyListPage from "../pages/third-party/list";
import ThirdPartyFormPage from "../pages/third-party/form";
import ProductListPage from "../pages/product/list";
import ProductFormPage from "../pages/product/form";
import PortfolioListPage from "../pages/portfolio/list";
import PortfolioFormPage from "../pages/portfolio/form";
import InsightListPage from "../pages/insight/list";
import InsightFormPage from "../pages/insight/form";
import DashboardCrmPage from "../pages/dashboard-crm";
import DashboardCmsPage from "../pages/dashboard-cms";
import PageListPage from "../pages/page/list";
import PageFormPage from "../pages/page/form";
import InboxListPage from "../pages/inbox/list";
import InboxDetailModule from "../modules/inbox/detail";
import RoleListPage from "../pages/role/list";
import RoleFormPage from "../pages/role/form";
import UserRoleListPage from "../pages/user-role/list";
import LeadListPage from "../pages/lead/list";
import LeadFormPage from "../pages/lead/form";
import LeadDetailPage from "../pages/lead/detail";
import GwProductListPage from "../pages/gw-product/list";
import GwProductFormPage from "../pages/gw-product/form";

const Profile = React.lazy(() => import("../pages/profile"));
const ChangePassword = React.lazy(() => import("../pages/change-password"));
const GwCustomerListPage = React.lazy(
  () => import("../pages/gw-customer/list")
);
const GwCustomerDetailPage = React.lazy(
  () => import("../pages/gw-customer/detail")
);
const EmailTrackerFormPage = React.lazy(
  () => import("../pages/email-tracker/form")
);
const EmailTrackerListPage = React.lazy(
  () => import("../pages/email-tracker/list")
);

const Home = React.lazy(() => import("../pages/home"));

export const appRoutes: RouteObject[] = [
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<VuiLoadingScreen />}>
            <Home />
          </React.Suspense>
        ),
      },
      {
        path: "dashboard",
        children: [
          {
            path: "crm",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <DashboardCrmPage />
              </React.Suspense>
            ),
          },
          {
            path: "cms",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <DashboardCmsPage />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "profile",
        element: (
          <React.Suspense fallback={<VuiLoadingScreen />}>
            <Profile />
          </React.Suspense>
        ),
      },
      {
        path: "change-password",
        element: (
          <React.Suspense fallback={<VuiLoadingScreen />}>
            <ChangePassword />
          </React.Suspense>
        ),
      },
      {
        path: "project",
        children: [
          {
            path: "",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <ProjectListPage />
              </React.Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <ProjectFormPage />
              </React.Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <ProjectFormPage />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "maintenance",
        children: [
          {
            path: "",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <MaintenanceListPage />
              </React.Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <MaintenanceFormPage />
              </React.Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <MaintenanceDetailPage />
              </React.Suspense>
            ),
          },
          {
            path: ":id/edit",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <MaintenanceFormPage />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "email-tracker",
        children: [
          {
            path: "",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <EmailTrackerListPage />
              </React.Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <EmailTrackerFormPage />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "domain",
        children: [
          {
            path: "",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <DomainListPage />
              </React.Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <DomainFormPage />
              </React.Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <DomainDetailPage />
              </React.Suspense>
            ),
          },
          {
            path: ":id/edit",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <DomainFormPage />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "lead",
        children: [
          {
            path: "",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <LeadListPage />
              </React.Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <LeadFormPage />
              </React.Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <LeadDetailPage />
              </React.Suspense>
            ),
          },
          {
            path: ":id/edit",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <LeadFormPage />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "google-workspace",
        children: [
          {
            path: "",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <GwCustomerListPage />
              </React.Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <GwCustomerDetailPage />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "invoice",
        children: [
          {
            path: "",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <InvoiceListPage />
              </React.Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <InvoiceFormPage />
              </React.Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <InvoiceDetailPage />
              </React.Suspense>
            ),
          },
          {
            path: ":id/edit",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <InvoiceFormPage />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "client",
        children: [
          {
            path: "",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <CustomerListPage />
              </React.Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <CustomerFormPage />
              </React.Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <CustomerFormPage />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "page",
        children: [
          {
            path: "",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <PageListPage />
              </React.Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <PageFormPage />
              </React.Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <PageFormPage />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "inbox",
        children: [
          {
            path: "",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <InboxListPage />
              </React.Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <InboxDetailModule />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "other-list",
        children: [
          {
            path: "",
            element: <Navigate to="category" />,
          },
          {
            path: "category",
            children: [
              {
                path: "",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <CategoryListPage />
                  </React.Suspense>
                ),
              },
              {
                path: "add",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <CategoryFormPage />
                  </React.Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <CategoryFormPage />
                  </React.Suspense>
                ),
              },
            ],
          },
          {
            path: "industry",
            children: [
              {
                path: "",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <IndustryListPage />
                  </React.Suspense>
                ),
              },
              {
                path: "add",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <IndustryFormPage />
                  </React.Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <IndustryFormPage />
                  </React.Suspense>
                ),
              },
            ],
          },
          {
            path: "third-party",
            children: [
              {
                path: "",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <ThirdPartyListPage />
                  </React.Suspense>
                ),
              },
              {
                path: "add",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <ThirdPartyFormPage />
                  </React.Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <ThirdPartyFormPage />
                  </React.Suspense>
                ),
              },
            ],
          },
          {
            path: "credential",
            children: [
              {
                path: "",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <CredentialListPage />
                  </React.Suspense>
                ),
              },
              {
                path: "add",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <CredentialFormPage />
                  </React.Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <CredentialFormPage />
                  </React.Suspense>
                ),
              },
            ],
          },
          {
            path: "product",
            children: [
              {
                path: "",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <ProductListPage />
                  </React.Suspense>
                ),
              },
              {
                path: "add",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <ProductFormPage />
                  </React.Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <ProductFormPage />
                  </React.Suspense>
                ),
              },
            ],
          },
          {
            path: "gw-product",
            children: [
              {
                path: "",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <GwProductListPage />
                  </React.Suspense>
                ),
              },
              {
                path: "add",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <GwProductFormPage />
                  </React.Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <GwProductFormPage />
                  </React.Suspense>
                ),
              },
            ],
          },
          {
            path: "unit",
            children: [
              {
                path: "",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <UnitListPage />
                  </React.Suspense>
                ),
              },
              {
                path: "add",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <UnitFormPage />
                  </React.Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <UnitFormPage />
                  </React.Suspense>
                ),
              },
            ],
          },
          {
            path: "project-type",
            children: [
              {
                path: "",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <ProjectTypeListPage />
                  </React.Suspense>
                ),
              },
              {
                path: "add",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <ProjectTypeFormPage />
                  </React.Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <React.Suspense fallback={<VuiLoadingScreen />}>
                    <ProjectTypeFormPage />
                  </React.Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "role",
        children: [
          {
            path: "",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <RoleListPage />
              </React.Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <RoleFormPage />
              </React.Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <RoleFormPage />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "user",
        children: [
          {
            path: "",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <UserListPage />
              </React.Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <UserFormPage />
              </React.Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <UserFormPage />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "user-role",
        children: [
          {
            path: "",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <UserRoleListPage />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "portfolio",
        children: [
          {
            path: "",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <PortfolioListPage />
              </React.Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <PortfolioFormPage />
              </React.Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <PortfolioFormPage />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "insight",
        children: [
          {
            path: "",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <InsightListPage />
              </React.Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <InsightFormPage />
              </React.Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <InsightFormPage />
              </React.Suspense>
            ),
          },
        ],
      },
    ],
  },
];
