import { Button, Form, InputNumber, Modal, Space } from "antd";
import { useTranslation } from "react-i18next";
import { ExtendFormData } from "../../detail/interface";
import VuiSelect from "../../../../components/select";
import ConstantRepository from "../../../../repositories/constant-repository";
import { ReactNode, useCallback } from "react";
import { MAINTENANCE_CANCEL_ID } from "../../../../constants";

export type ModalExtendProps = {
  show: boolean;
  onClose: () => void;
  submitLoading: boolean;
  onSubmit: (formData: ExtendFormData) => void;
  title: string | ReactNode;
};

const ModalExtend = ({
  show,
  onClose,
  submitLoading,
  onSubmit,
  title,
}: ModalExtendProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const watchAction = Form.useWatch("action", form);

  const handleOnClose = useCallback(() => {
    form.resetFields();
    onClose();
  }, [onClose, form]);

  return (
    <Modal
      title={title}
      centered
      visible={show}
      onCancel={handleOnClose}
      footer={[
        <Button key="cancel-button" onClick={handleOnClose}>
          {t("common.button.cancel")}
        </Button>,
        <Button
          key="submit-button"
          type="primary"
          loading={submitLoading}
          onClick={() => {
            form
              .validateFields()
              .then((values: ExtendFormData) => {
                onSubmit(values);
                form.resetFields();
              })
              .catch((info: string) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          {t("common.button.submit")}
        </Button>,
      ]}
    >
      <Form form={form} layout={"vertical"}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Form.Item
            name="action"
            label={t("common.form.action.label")}
            rules={[
              {
                required: true,
                message: t("validation.required", {
                  item: t("common.form.action.label"),
                }),
              },
            ]}
          >
            <VuiSelect
              repository={ConstantRepository}
              repositoryParams={{
                for: "extend_maintenance",
              }}
              labelKey="label"
              placeholder={t("common.form.action.placeholder")}
            />
          </Form.Item>

          {watchAction?.value !== MAINTENANCE_CANCEL_ID && (
            <Form.Item
              name="duration"
              label={t("common.form.durationMonth.label")}
              rules={[
                {
                  required: true,
                  message: t("validation.required", {
                    item: t("common.form.durationMonth.label"),
                  }),
                },
              ]}
            >
              <InputNumber
                min={1}
                size="large"
                placeholder={t("common.form.durationMonth.placeholder")}
              />
            </Form.Item>
          )}
        </Space>
      </Form>
    </Modal>
  );
};

export default ModalExtend;
