import { EditOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Spin, Tag, Timeline, Typography } from "antd";
import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import VuiFormItemView from "../../../components/form-item-view";
import VuiPageTitle from "../../../components/page-title";
import { LEAD_STATUS_COLOR } from "../../../constants";
import { useBreadcrumb } from "../../../context/breadcrumb";
import { BreadcrumbActionType } from "../../../context/breadcrumb/reducer";
import ModalFormActivity from "../components/modal-form-activity";
import { useFormLead } from "../hooks/use-form-lead";
import { useGetLead } from "../hooks/use-get-lead";
import { ActivityFormData, ActivityPayloadType } from "./interface";
import VuiAccessible from "../../../components/accessible";
import VuiModalConfirmation from "../../../components/modal-confirmation";
import { openNotification, snakeCaseToTitleCase } from "../../../utils/helpers";
import Space from "antd/es/space";
import ModalDetailActivity from "../components/modal-detail-activity";

const { Text, Title } = Typography;

const LeadDetailModule = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { loadDetailData, isOnFetchingDetailData, detailData } = useGetLead();
  const title = `${t("common.text.leads")} | `;
  const {
    activityLoading,
    onActivity,
    onDelete,
    deleteLoading,
    onDeleteActivity,
    activityDeleteLoading,
  } = useFormLead();
  const { dispatch } = useBreadcrumb();
  const [showActivityDetail, setShowActivityDetail] = useState(false);
  const [showActivityForm, setShowActivityForm] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showDeleteActivityModal, setShowDeleteActivityModal] =
    useState<boolean>(false);
  const [deleteActivityId, setDeleteActivityId] = useState<string | null>(null);
  const [showActivityIndex, setShowActivityIndex] = useState<number | null>(
    null
  );

  const name = useMemo(() => {
    return detailData?.lead_name || detailData?.customer?.name || "-";
  }, [detailData]);

  const emails = useMemo(() => {
    return detailData?.emails?.map((contact) => contact).join(", ");
  }, [detailData]);

  const phones = useMemo(() => {
    return detailData?.phones?.map((contact) => contact).join(", ");
  }, [detailData]);

  const products = useMemo(() => {
    return detailData?.products?.map((product) => product.name).join(", ");
  }, [detailData]);

  useEffect(() => {
    dispatch({
      type: BreadcrumbActionType.Update,
      payload: [
        {
          link: "/",
          title: t("common.text.home"),
        },
        {
          link: "/lead",
          title: t("common.text.lead"),
        },
        {
          link: `/lead/${id}`,
          title: name || t("common.text.detail"),
          isDisabled: true,
        },
      ],
    });
  }, [dispatch, t, id]);

  useEffect(() => {
    if (id) {
      (async () => {
        await loadDetailData(id, {
          with: [
            "customer",
            "type",
            "industry",
            "priority",
            "assign",
            "products",
            "activities.user",
            "activities.contactVia",
            "activities.meetingVia",
            "activities.proposalSentVia",
            "activities.proposalAttachment",
          ],
        });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleEdit = () => {
    navigate(`/lead/${id}/edit`);
  };

  const handleActivity = async (formData: ActivityFormData) => {
    const normalizeData: ActivityPayloadType = {
      ...formData,
      status: formData.status?.value as string,
      date: moment(formData.date).format("YYYY-MM-DD HH:mm:ss"),
      contact_date: moment(formData.contact_date).format("YYYY-MM-DD HH:mm:ss"),
      meeting_date: moment(formData.meeting_date).format("YYYY-MM-DD HH:mm:ss"),
      proposal_sent_date: moment(formData.proposal_sent_date).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      proposal_estimate_date: moment(formData.proposal_estimate_date).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      user_id: formData.user_id?.value as number,
      contact_via_ids: formData.contact_via_ids?.map(
        (item) => item.value as number
      ),
      meeting_via_id: formData.meeting_via_id?.value as number,
      proposal_sent_via_id: formData.proposal_sent_via_id?.value as number,
      proposal_attachment_id:
        formData.proposal_attachment_id &&
        formData.proposal_attachment_id.length
          ? (formData.proposal_attachment_id[0].id as number)
          : undefined,
      meeting_participants: formData.meeting_participants?.filter(
        (item) => item.name || item.email || item.whatsapp
      ),
    };

    if (id) {
      await onActivity(id, normalizeData);
      setShowActivityForm(false);
      await loadDetailData(id, {
        with: [
          "customer",
          "type",
          "industry",
          "priority",
          "assign",
          "products",
          "activities.user",
          "activities.contactVia",
          "activities.meetingVia",
          "activities.proposalSentVia",
          "activities.proposalAttachment",
        ],
      });
    }
  };

  const handleCloseModalDelete = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const handleCloseModalDeleteActivity = useCallback(() => {
    setShowDeleteActivityModal(false);
    setDeleteActivityId(null);
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    if (id) {
      await onDelete(id).then(() => {
        setShowDeleteModal(false);
        openNotification(
          "success",
          t("notification.success.deleteItem", {
            item: t("common.text.leads"),
          })
        );

        navigate(`/lead`);
      });
    }
  }, [id, navigate, onDelete, t]);

  const handleConfirmDeleteActivity = useCallback((): void => {
    if (id) {
      onDeleteActivity(id, deleteActivityId ?? "").then(() => {
        setShowDeleteActivityModal(false);
        setDeleteActivityId(null);
        setShowActivityDetail(false);

        loadDetailData(id, {
          with: [
            "customer",
            "type",
            "industry",
            "priority",
            "assign",
            "products",
            "activities.user",
            "activities.contactVia",
            "activities.meetingVia",
            "activities.proposalSentVia",
            "activities.proposalAttachment",
          ],
        }).catch();
      });
    }
  }, [id, navigate, onDeleteActivity, deleteActivityId, t]);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <VuiPageTitle
            title={
              <Title level={4} style={{ fontWeight: 500, marginBottom: 0 }}>
                {title}
                <Text style={{ fontWeight: 800 }}>{name}</Text>
              </Title>
            }
            onBackLink="/lead"
            rightTitleContent={[
              <Tag
                color={_.get(
                  LEAD_STATUS_COLOR,
                  detailData?.status ?? "",
                  "blue"
                )}
              >
                {snakeCaseToTitleCase(detailData?.status ?? " ")}
              </Tag>,
            ]}
          >
            <VuiAccessible access="lead.update">
              {detailData?.can_update && (
                <Button
                  size="large"
                  type="primary"
                  ghost
                  icon={<EditOutlined />}
                  onClick={handleEdit}
                />
              )}
            </VuiAccessible>

            {detailData?.can_activity && (
              <Button
                size="large"
                type="primary"
                ghost
                onClick={() => setShowActivityForm(true)}
              >
                {t("common.text.updateActivity")}
              </Button>
            )}
          </VuiPageTitle>
        </Col>
      </Row>

      <Spin size="large" spinning={isOnFetchingDetailData}>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Card>
              <Row gutter={[48, 16]}>
                <Col xs={24} md={8}>
                  <Row gutter={[16, 16]}>
                    {detailData?.existing_client && (
                      <>
                        <Col xs={24} md={24}>
                          <VuiFormItemView
                            label={t("common.form.client.label")}
                            value={detailData?.customer?.name}
                          />
                        </Col>
                      </>
                    )}
                    {!detailData?.existing_client && (
                      <>
                        <Col xs={24} md={12}>
                          <VuiFormItemView
                            label={t("common.form.leadName.label")}
                            value={
                              detailData?.type?.label && detailData?.lead_name
                                ? `${detailData?.type?.label} ${detailData?.lead_name}`
                                : "-"
                            }
                          />
                        </Col>
                        <Col xs={24} md={12}>
                          <VuiFormItemView
                            label={t("common.form.industry.label")}
                            value={detailData?.industry?.name}
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                </Col>
                <Col xs={24} md={16}>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} md={8}>
                      <VuiFormItemView
                        label={t("common.form.date.label")}
                        value={moment(detailData?.date).format("DD MMM YYYY")}
                      />
                    </Col>
                    <Col xs={24} md={8}>
                      <VuiFormItemView
                        label={t("common.form.priority.label")}
                        value={detailData?.priority?.label}
                      />
                    </Col>
                    <Col xs={24} md={8}>
                      <VuiFormItemView
                        label={t("common.form.assignee.label")}
                        value={detailData?.assign?.name}
                      />
                    </Col>
                    <Col xs={24} md={8}>
                      <VuiFormItemView
                        label={t("common.form.emails.label")}
                        value={emails ?? "-"}
                      />
                    </Col>
                    <Col xs={24} md={8}>
                      <VuiFormItemView
                        label={t("common.form.phones.label")}
                        value={phones ?? "-"}
                      />
                    </Col>
                    <Col xs={24} md={8}>
                      <VuiFormItemView
                        label={t("common.form.potentialServices.label")}
                        value={`${products}`}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} md={12}>
            <Card>
              <Timeline>
                {detailData?.activities
                  ?.slice()
                  .reverse()
                  .map((activity) => {
                    return (
                      <Timeline.Item
                        color={_.get(
                          LEAD_STATUS_COLOR,
                          activity.status,
                          "blue"
                        )}
                      >
                        <Space
                          style={{ width: "100%" }}
                          direction="vertical"
                          size={10}
                        >
                          <div>
                            <Text>
                              Status updated to{" "}
                              <Text strong>
                                {snakeCaseToTitleCase(activity.status)}
                              </Text>{" "}
                              and assigned to{" "}
                              <Text strong>{activity.user?.name}</Text>
                            </Text>
                          </div>
                          {activity.status === "PROPOSAL_PROGRESS" && (
                            <Tag style={{ color: "#757575" }}>
                              {activity?.proposal_code}
                            </Tag>
                          )}
                          {activity.note && (
                            <div>
                              <Text>Note: {activity.note}</Text>
                            </div>
                          )}
                          <div>
                            <Text type="secondary">
                              {moment(activity.date).format("DD MMM YYYY")}
                            </Text>
                          </div>
                          <div>
                            <Space direction="horizontal" size={10}>
                              <Button
                                size="small"
                                type="primary"
                                ghost
                                onClick={() => {
                                  setShowActivityIndex(
                                    detailData?.activities.findIndex(
                                      (item) => item.id === activity.id
                                    )
                                  );
                                  setShowActivityDetail(true);
                                }}
                              >
                                View
                              </Button>
                            </Space>
                          </div>
                        </Space>
                      </Timeline.Item>
                    );
                  })}
                {!["WON", "LOST"].includes(detailData?.status ?? "") &&
                  detailData?.activities?.length === 0 && (
                    <Timeline.Item color="gray" />
                  )}
              </Timeline>
            </Card>
          </Col>
          <VuiAccessible access="lead.destroy">
            {detailData?.can_delete && (
              <Col xs={24}>
                <Text
                  className="cursor-pointer"
                  onClick={() => setShowDeleteModal(true)}
                  title={t("common.text.delete")}
                  type="secondary"
                >
                  {t("common.text.delete")}
                </Text>
              </Col>
            )}
          </VuiAccessible>
        </Row>
      </Spin>

      {showActivityForm && (
        <ModalFormActivity
          title={
            <>
              <Title
                level={5}
                style={{
                  color: " #979DAD",
                  fontWeight: 400,
                }}
              >
                Update Activity
                <br />
                <Title level={4}>{name}</Title>
              </Title>
            </>
          }
          show={showActivityForm}
          onClose={() => setShowActivityForm(false)}
          onSubmit={handleActivity}
          submitLoading={activityLoading}
          availableStatuses={detailData?.available_statuses ?? []}
        />
      )}
      <ModalDetailActivity
        title={
          <>
            <Title
              level={5}
              style={{
                color: " #979DAD",
                fontWeight: 400,
              }}
            >
              View Activity
              <br />
              <Title level={4}>
                <Tag
                  color={_.get(
                    LEAD_STATUS_COLOR,
                    detailData?.activities[showActivityIndex ?? 0]?.status ??
                      "",
                    "blue"
                  )}
                >
                  {snakeCaseToTitleCase(
                    detailData?.activities[showActivityIndex ?? 0]?.status ?? ""
                  )}
                </Tag>
              </Title>
            </Title>
          </>
        }
        show={showActivityDetail}
        onClose={() => setShowActivityDetail(false)}
        onDelete={() => {
          setDeleteActivityId(
            detailData?.activities[showActivityIndex ?? 0].id.toString() ?? null
          );
          setShowDeleteActivityModal(true);
        }}
        data={detailData?.activities[showActivityIndex ?? 0]}
      />
      <VuiModalConfirmation
        show={showDeleteModal}
        isLoading={deleteLoading}
        onSubmit={handleConfirmDelete}
        onCancel={handleCloseModalDelete}
      />
      <VuiModalConfirmation
        show={showDeleteActivityModal}
        isLoading={activityDeleteLoading}
        onSubmit={handleConfirmDeleteActivity}
        onCancel={handleCloseModalDeleteActivity}
      />
    </>
  );
};

export default LeadDetailModule;
