import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Space,
} from "antd";
import { useTranslation } from "react-i18next";
import { ActivityFormData } from "../../detail/interface";
import VuiSelect from "../../../../components/select";
import ConstantRepository from "../../../../repositories/constant-repository";
import { ReactNode } from "react";
import { filteredLeadStatusOptions } from "../../../../utils/helpers";
import VuiDatePicker from "../../../../components/vui-date-picker";
import moment from "moment";
import UserRepository from "../../../../repositories/user-repository";
import { DeleteOutlined } from "@ant-design/icons";
import VuiUploadImage from "../../../../components/upload-image";

export type ModalFormActivityProps = {
  show: boolean;
  onClose: () => void;
  submitLoading: boolean;
  onSubmit: (formData: ActivityFormData) => void;
  title: string | ReactNode;
  availableStatuses: string[];
};

const leadActivityParticipantDefaultValue = {
  name: null,
  email: null,
  whatsapp: null,
};

const ModalFormActivity = ({
  show,
  onClose,
  submitLoading,
  onSubmit,
  title,
  availableStatuses,
}: ModalFormActivityProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const watchStatus = Form.useWatch("status", form);
  const watchMeetingVia = Form.useWatch("meeting_via_id", form);

  return (
    <Modal
      title={title}
      width={"50%"}
      centered
      visible={show}
      onCancel={onClose}
      footer={[
        <Button key="cancel-button" onClick={onClose}>
          {t("common.button.cancel")}
        </Button>,
        <Button
          key="submit-button"
          type="primary"
          loading={submitLoading}
          onClick={() => {
            form
              .validateFields()
              .then((values: ActivityFormData) => {
                onSubmit(values);
                form.resetFields();
              })
              .catch((info: string) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          {t("common.button.submit")}
        </Button>,
      ]}
    >
      <Form
        form={form}
        initialValues={{
          date: moment(),
          contact_date: moment(),
          meeting_date: moment(),
          proposal_estimate_date: moment(),
          proposal_sent_date: moment(),
        }}
        layout={"vertical"}
      >
        <Row gutter={[16, 8]}>
          <Col xs={24} md={8}>
            <Form.Item
              name="date"
              label={t("common.form.date.label")}
              rules={[
                {
                  required: true,
                  message: t("validation.required", {
                    item: t("common.form.date.label"),
                  }),
                },
              ]}
            >
              <VuiDatePicker />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="status"
              label={t("common.form.updateStatusTo.label")}
              rules={[
                {
                  required: true,
                  message: t("validation.required", {
                    item: t("common.form.updateStatusTo.label"),
                  }),
                },
              ]}
            >
              <VuiSelect
                defaultOptions={filteredLeadStatusOptions(t, availableStatuses)}
                placeholder={t("common.text.updateStatusTo")}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="user_id"
              label={t("common.form.assignee.label")}
              rules={[
                {
                  required: true,
                  message: t("validation.required", {
                    item: t("common.form.assignee.label"),
                  }),
                },
              ]}
            >
              <VuiSelect
                repository={UserRepository}
                placeholder={t("common.form.assignee.placeholder")}
              />
            </Form.Item>
          </Col>

          {watchStatus?.value === "CONTACTED" && (
            <>
              <Col xs={24} md={8}>
                <Form.Item
                  name="contact_date"
                  label={t("common.form.contactDate.label")}
                  rules={[
                    {
                      required: true,
                      message: t("validation.required", {
                        item: t("common.form.contactDate.label"),
                      }),
                    },
                  ]}
                >
                  <VuiDatePicker />
                </Form.Item>
              </Col>
              <Col xs={24} md={16}>
                <Form.Item
                  name="contact_via_ids"
                  label={t("common.form.contactVia.label")}
                  rules={[
                    {
                      required: true,
                      message: t("validation.required", {
                        item: t("common.form.contactVia.label"),
                      }),
                    },
                  ]}
                >
                  <VuiSelect
                    repository={ConstantRepository}
                    repositoryParams={{
                      for: "contact_via",
                    }}
                    mode={"multiple"}
                    labelKey="label"
                    placeholder={t("common.form.contactVia.placeholder")}
                  />
                </Form.Item>
              </Col>
            </>
          )}

          {watchStatus?.value === "MEETING_SCHEDULED" && (
            <>
              <Col xs={24} md={8}>
                <Form.Item
                  name="meeting_date"
                  label={t("common.form.meetingDate.label")}
                  rules={[
                    {
                      required: true,
                      message: t("validation.required", {
                        item: t("common.form.date.label"),
                      }),
                    },
                  ]}
                >
                  <VuiDatePicker
                    format="DD MMM YYYY, HH:mm"
                    showTime={{ format: "HH:mm" }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="meeting_via_id"
                  label={t("common.form.meetingVia.label")}
                  rules={[
                    {
                      required: true,
                      message: t("validation.required", {
                        item: t("common.form.meetingVia.label"),
                      }),
                    },
                  ]}
                >
                  <VuiSelect
                    repository={ConstantRepository}
                    repositoryParams={{
                      for: "meeting_via",
                    }}
                    labelKey="label"
                    placeholder={t("common.form.meetingVia.placeholder")}
                  />
                </Form.Item>
              </Col>
              {watchMeetingVia?.label === "Offline" && (
                <Col xs={24} md={8}>
                  <Form.Item
                    name="meeting_address"
                    label={t("common.form.meetingAddress.label")}
                    rules={[
                      {
                        required: true,
                        message: t("validation.required", {
                          item: t("common.form.meetingAddress.label"),
                        }),
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder={t("common.form.meetingAddress.placeholder")}
                    />
                  </Form.Item>
                </Col>
              )}
              {watchMeetingVia?.label === "Online" && (
                <Col xs={24} md={8}>
                  <Form.Item
                    name="meeting_link"
                    label={t("common.form.meetingLink.label")}
                    rules={[
                      {
                        required: true,
                        message: t("validation.required", {
                          item: t("common.form.meetingLink.label"),
                        }),
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder={t("common.form.meetingLink.placeholder")}
                    />
                  </Form.Item>
                </Col>
              )}
              <Col xs={24}>
                <Card title={"Participants"}>
                  <Form.List
                    name={["meeting_participants"]}
                    initialValue={[
                      {
                        ...leadActivityParticipantDefaultValue,
                      },
                    ]}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <Space
                        style={{
                          width: "100%",
                        }}
                        direction="vertical"
                        size={10}
                      >
                        <Form.ErrorList errors={errors} />
                        <div className="ant-table-wrapper">
                          <div className="ant-table ant-table-small">
                            <div className="ant-table-container">
                              <div
                                className="ant-table-content"
                                style={{ overflowX: "auto" }}
                              >
                                <table style={{ tableLayout: "auto" }}>
                                  <thead className="ant-table-thead">
                                    <tr>
                                      <th className="ant-table-cell">Name</th>
                                      <th className="ant-table-cell">Email</th>
                                      <th className="ant-table-cell">
                                        WhatsApp
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="ant-table-tbody">
                                    {fields.map(
                                      ({ key, name, ...restField }) => {
                                        return (
                                          <tr
                                            key={key}
                                            className="ant-table-row ant-table-row-level-0"
                                          >
                                            <td className="ant-table-cell">
                                              <Form.Item
                                                className="mb0"
                                                {...restField}
                                                name={[name, "name"]}
                                              >
                                                <Input
                                                  onChange={() => {
                                                    if (!fields[name + 1]) {
                                                      add({
                                                        ...leadActivityParticipantDefaultValue,
                                                      });
                                                    }
                                                  }}
                                                  size="large"
                                                  placeholder={t(
                                                    "common.form.name.placeholder"
                                                  )}
                                                />
                                              </Form.Item>
                                            </td>

                                            <td className="ant-table-cell">
                                              <Form.Item
                                                className="mb0"
                                                {...restField}
                                                name={[name, "email"]}
                                              >
                                                <Input
                                                  size="large"
                                                  placeholder={"Email"}
                                                />
                                              </Form.Item>
                                            </td>

                                            <td className="ant-table-cell">
                                              <Form.Item
                                                className="mb0"
                                                {...restField}
                                                name={[name, "whatsapp"]}
                                              >
                                                <Input
                                                  size="large"
                                                  placeholder={t(
                                                    "common.form.whatsapp.placeholder"
                                                  )}
                                                />
                                              </Form.Item>
                                            </td>

                                            <td className="ant-table-cell">
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  columnGap: 10,
                                                }}
                                              >
                                                <div
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() => remove(name)}
                                                >
                                                  <DeleteOutlined
                                                    style={{ fontSize: 18 }}
                                                  />
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Space>
                    )}
                  </Form.List>
                </Card>
              </Col>
            </>
          )}

          {watchStatus?.value === "PROPOSAL_PROGRESS" && (
            <Col xs={24} md={8}>
              <Form.Item
                name="proposal_estimate_date"
                label={t("common.form.estimateDate.label")}
                rules={[
                  {
                    required: true,
                    message: t("validation.required", {
                      item: t("common.form.estimateDate.label"),
                    }),
                  },
                ]}
              >
                <VuiDatePicker />
              </Form.Item>
            </Col>
          )}

          {watchStatus?.value === "PROPOSAL_SENT" && (
            <>
              <Col xs={24} md={8}>
                <Form.Item
                  name="proposal_sent_date"
                  label={t("common.form.sentDate.label")}
                  rules={[
                    {
                      required: true,
                      message: t("validation.required", {
                        item: t("common.form.sentDate.label"),
                      }),
                    },
                  ]}
                >
                  <VuiDatePicker />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="proposal_sent_via_id"
                  label={t("common.form.sentVia.label")}
                  rules={[
                    {
                      required: true,
                      message: t("validation.required", {
                        item: t("common.form.sentVia.label"),
                      }),
                    },
                  ]}
                >
                  <VuiSelect
                    repository={ConstantRepository}
                    repositoryParams={{
                      for: "proposal_sent_via",
                    }}
                    labelKey="label"
                    placeholder={t("common.form.sentVia.placeholder")}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="proposal_amount"
                  label={t("common.form.amount.label")}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    size="large"
                    placeholder={t("common.form.amount.placeholder")}
                  />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="proposal_attachment_id"
                  label={t("common.form.attachment.label")}
                >
                  <VuiUploadImage />
                </Form.Item>
              </Col>
            </>
          )}

          {watchStatus?.value === "LOST" && (
            <Col xs={24}>
              <Form.Item
                name="lost_reason"
                label={t("common.form.lostReason.label")}
                rules={[
                  {
                    required: true,
                    message: t("validation.required", {
                      item: t("common.form.lostReason.label"),
                    }),
                  },
                ]}
              >
                <Input.TextArea
                  size="large"
                  placeholder={t("common.form.lostReason.placeholder")}
                  rows={2}
                />
              </Form.Item>
            </Col>
          )}

          <Col xs={24}>
            <Form.Item name="note" label={t("common.form.note.label")}>
              <Input.TextArea
                size="large"
                placeholder={t("common.form.note.placeholder")}
                rows={3}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalFormActivity;
