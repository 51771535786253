import { Tag, Tooltip } from "antd";
import { hourToMillisecond } from "../../utils/helpers";
import { useCallback, useMemo } from "react";

const humanizeDuration = require("humanize-duration");

interface ExpiredStatusInterface {
  expiredIn: number;
  label?: string;
}

const ExpiredStatus = ({ expiredIn = 0, label }: ExpiredStatusInterface) => {
  const statusColor = useMemo(() => {
    if (label === "Cancelled") return "#808080";

    const days = Math.round(expiredIn / 24);
    if (days >= 30) {
      return "#62D88F";
    } else {
      if (days < 3) {
        return "#F44336";
      }
      return "#FAAD14";
    }
  }, [expiredIn]);

  const getStatus = useCallback(
    (isFull?: boolean) => {
      if (label === "Cancelled") return "Cancelled";

      return expiredIn > 0
        ? humanizeDuration(hourToMillisecond(expiredIn), {
            largest: isFull ? 3 : 1,
            units: ["y", "mo", "d"],
            round: true,
            delimiter: " ",
          })
        : "Expired";
    },
    [expiredIn]
  );

  return (
    <Tooltip className="text-capitalize" title={getStatus(true)}>
      <Tag className="text-capitalize" color={statusColor}>
        {label !== "On Going" ? label ?? getStatus() : getStatus()}
      </Tag>
    </Tooltip>
  );
};

export default ExpiredStatus;
