import "./_style.less";
import { VuiTableActionButtonProps } from "./interface";
import { useNavigate } from "react-router-dom";
import VuiAccessible from "../accessible";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";

const VuiTableActionButton = (props: VuiTableActionButtonProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const {
    detailPermissionName,
    deletePermissionName,
    onClickDelete,
    detailLabel = t("common.text.view"),
    deleteBtnLabel = t("common.text.delete"),
    detailLink = "",
    showDelete = true,
    showDetail = true,
    onClickDetail,
  } = props;
  const [child, setChild] = useState(0);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    setChild(ref.current?.childNodes.length || 0);
  }, []);

  return (
    <div
      className={`table-action ${
        child < 2 ? "single-action" : "double-action"
      }`}
      ref={ref}
    >
      <VuiAccessible access={detailPermissionName as string}>
        {showDetail && (
          <Tooltip title={detailLabel}>
            <button
              className="btn-plain btn-action view"
              onClick={() => {
                if (onClickDetail) {
                  onClickDetail();
                } else if (detailLink) {
                  navigate(detailLink);
                }
              }}
            >
              <EyeOutlined />
            </button>
          </Tooltip>
        )}
      </VuiAccessible>

      {showDelete && (
        <VuiAccessible access={deletePermissionName as string}>
          <Tooltip title={deleteBtnLabel}>
            <button
              className="btn-plain btn-action delete"
              onClick={onClickDelete}
            >
              <DeleteOutlined />
            </button>
          </Tooltip>
        </VuiAccessible>
      )}
    </div>
  );
};

export default VuiTableActionButton;
