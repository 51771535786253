import { Typography } from "antd";
import { VuiFormItemViewProps } from "./interface";
import VuiUploadImage from "../upload-image";
import { useMemo } from "react";
import { toFileList } from "../upload-image/function";

const { Text } = Typography;

const VuiFormItemView = ({
  label,
  type = "text",
  value,
  className = "fs-12",
}: VuiFormItemViewProps) => {
  const renderValue = useMemo(() => {
    return type === "image" ? (
      <VuiUploadImage disabled value={toFileList(value)} />
    ) : (
      <div>{value || "-"}</div>
    );
  }, [type, value]);

  return (
    <div className="ant-form-item">
      <Text type="secondary" className={className}>
        {label}
      </Text>
      {renderValue}
    </div>
  );
};

export default VuiFormItemView;
