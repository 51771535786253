import { TFunction } from "react-i18next";
import { ReactNode } from "react";
import {
  AppstoreOutlined,
  AuditOutlined,
  ContactsOutlined,
  CopyOutlined,
  DashboardOutlined,
  FileTextOutlined,
  FolderOutlined,
  HomeOutlined,
  InboxOutlined,
  KeyOutlined,
  ReadOutlined,
  ToolOutlined,
  UserOutlined,
} from "@ant-design/icons";
import GoogleIcon from "../assets/icons/google-icon";

export type MenuDataType = {
  type?: "submenu" | "group";
  title: string;
  key: string;
  access: string | string[];
  icon?: ReactNode;
  children?: MenuDataType[];
};

export const generateMenu = (t: TFunction): MenuDataType[] => {
  return [
    {
      title: t("common.text.home"),
      key: "/",
      access: "any",
      icon: <HomeOutlined style={{ fontSize: 18, color: "#707070" }} />,
    },
    // {
    //   title: t("common.text.dashboard"),
    //   key: "/dashboard",
    //   access: "any",
    //   icon: <DashboardOutlined style={{ fontSize: 18, color: "#707070" }} />,
    //   children: [
    //     {
    //       title: t("common.text.crm"),
    //       key: "/dashboard/crm",
    //       access: "any",
    //     },
    //     {
    //       title: t("common.text.cms"),
    //       key: "/dashboard/cms",
    //       access: "any",
    //     },
    //   ],
    // },
    {
      type: "group",
      title: `${t("common.text.crm")}`,
      key: "/crm",
      access: [
        "project.index",
        "maintenance.index",
        "domain.index",
        "invoice.index",
        "customer.index",
        "email-tracker.index",
      ],
      children: [
        {
          title: t("common.text.leads"),
          key: "/lead",
          access: "lead.index",
          icon: <AuditOutlined style={{ fontSize: 18, color: "#707070" }} />,
        },
        {
          title: t("common.text.project"),
          key: "/project-parent",
          access: ["project.index", "maintenance.index", "domain.index"],
          icon: <FolderOutlined style={{ fontSize: 18, color: "#707070" }} />,
          children: [
            {
              title: t("common.text.list"),
              key: "/project",
              access: "project.index",
            },
            {
              title: t("common.text.maintenance"),
              key: "/maintenance",
              access: "maintenance.index",
            },
            {
              title: t("common.text.domain"),
              key: "/domain",
              access: "domain.index",
            },
          ],
        },
        {
          title: t("common.text.googleWorkspace"),
          key: "/google-workspace",
          access: "gw-customer.index",
          icon: <GoogleIcon />,
        },
        {
          title: t("common.text.invoice"),
          key: "/invoice",
          access: "invoice.index",
          icon: <FileTextOutlined style={{ fontSize: 18, color: "#707070" }} />,
        },
        {
          title: t("common.text.client"),
          key: "/client",
          access: "customer.index",
          icon: <ContactsOutlined style={{ fontSize: 18, color: "#707070" }} />,
        },
        {
          title: t("common.text.tools"),
          key: "/tools",
          access: ["email-tracker.index"],
          icon: <ToolOutlined style={{ fontSize: 18, color: "#707070" }} />,
          children: [
            {
              title: t("common.text.emailTracker"),
              key: "/email-tracker",
              access: "email-tracker.index",
            },
          ],
        },
      ],
    },
    {
      type: "group",
      title: `${t("common.text.website")}`,
      key: "/website",
      access: ["portfolio.index", "insight.index", "page.index"],
      children: [
        {
          title: t("common.text.page"),
          key: "/page",
          access: "page.index",
          icon: <CopyOutlined style={{ fontSize: 18, color: "#707070" }} />,
        },
        {
          title: t("common.text.inbox"),
          key: "/inbox",
          access: "inbox.index",
          icon: <InboxOutlined style={{ fontSize: 18, color: "#707070" }} />,
        },
        {
          type: "submenu",
          title: t("common.text.content"),
          key: "/content",
          access: ["portfolio.index", "insight.index"],
          icon: <ReadOutlined style={{ fontSize: 18, color: "#707070" }} />,
          children: [
            {
              title: t("common.text.portfolio"),
              key: "/portfolio",
              access: "portfolio.index",
            },
            {
              title: t("common.text.insight"),
              key: "/insight",
              access: "insight.index",
            },
          ],
        },
      ],
    },
    {
      type: "group",
      title: `${t("common.text.setting")}`,
      key: "/setting",
      access: [
        "category.index",
        "industry.index",
        "credential.index",
        "project-type.index",
        "third-party.index",
        "unit.index",
        "product.index",
        "user.index",
        "role.index",
      ],
      children: [
        // {
        //   title: t("common.text.user"),
        //   key: "/user",
        //   access: "user.index",
        //   icon: <UserOutlined style={{ fontSize: 18, color: "#707070" }} />,
        // },
        // {
        //   title: t("common.text.role"),
        //   key: "/role",
        //   access: "role.index",
        //   icon: <KeyOutlined style={{ fontSize: 18, color: "#707070" }} />,
        // },
        {
          title: t("common.text.userAndRole"),
          key: "/user-role",
          access: ["user.index", "role.index"],
          icon: <UserOutlined style={{ fontSize: 18, color: "#707070" }} />,
        },
        {
          title: t("common.text.otherList"),
          key: "/other-list",
          access: [
            "category.index",
            "industry.index",
            "credential.index",
            "project-type.index",
            "third-party.index",
            "unit.index",
            "product.index",
            "gw-product.index",
          ],
          icon: <AppstoreOutlined style={{ fontSize: 18, color: "#707070" }} />,
        },
      ],
    },
  ];
};
